import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { navigate } from 'gatsby';

import ErrorHeader from '../../components/404/ErrorHeader';
import ThankYou from '../../components/404/ThankYou';

export default function ErrorPage() {

  const { i18n } = useTranslation();

  if(Object.keys(i18n).length === 0 && i18n.constructor === Object) {
    navigate(`/`);
    return null;
  }

  const [lang, setLang] = useState();

  useEffect(() => {
    if (isEnglish()) {
      i18n.changeLanguage('en');
      setLang('en');
    } else {
      i18n.changeLanguage('de');
      setLang('de');
    }
  }, [i18n])

  const isEnglish = () => {
    let path = window.location.pathname;
    return (/(\/en)/g).test(path);
  }

  return (
    <section>
      <ErrorHeader />
      <ThankYou lang={lang} />

    </section>
  )
  
}
