import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import Chevron from '../../../images/SubPages/Chevron';
import Envelope from '../../../images/SubPages/Envelope.png';

import './ThankYou.scss';

export default function ThankYou() {

  const { t } = useTranslation();

  return (
    <div className='thank-you-container'>
      <div className='thank-you-wrapper'>
        <img src={Envelope} alt='Envelope' className='thank-you-image' />
        <h1 className='thank-you-title'>{t('thankyou.title')}</h1>
        <h2 className='thank-you-subtitle-upper'>{t('thankyou.subtitle1')}</h2>
        <br/>
        <h2 className='thank-you-subtitle-lower'>{t('thankyou.subtitle2')}</h2>
        <div className='thank-you-button-block'>
          <Link to='/' className='thank-you-button-link'>
            <Chevron svgClass='chevron first' />
            <Chevron svgClass='chevron second' />
            <Chevron svgClass='chevron third' />
            {t('thankyou.btn')}
          </Link>
        </div>
      </div>
    </div>
  )
}
