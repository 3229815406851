import React from 'react';

export default function Chevron({svgClass}) {
  return (
    <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={svgClass}>
      <path d="M12.5257 19.6266C11.8329 20.3895 10.6349 20.4623 9.8498 19.7892L0.641507 11.8944C0.249087 11.558 0.0163293 11.0083 -4.26186e-07 10.5C0.0163294 9.99174 0.249117 9.44202 0.641537 9.10558L9.84983 1.21085C10.6349 0.537738 11.833 0.610492 12.5257 1.37335C13.2185 2.13621 13.1436 3.30029 12.3585 3.9734L4.49999 10.5L12.3584 17.0266C13.1435 17.6997 13.2184 18.8638 12.5257 19.6266Z" fill="white" />
    </svg>

  )
}
