import React from 'react';

import Logo from '../../../images/Logo';

import './ErrorHeader.scss';

export default function Error() {
  return (
    <div className='error-header-container'>
      <div className='error-header-wrapper'>
        <a href='https://os-system.com' target='_blank' rel='noreferrer'>
          <Logo />
        </a>
      </div>
    </div>
  )
}
